/* eslint-disable jsx-a11y/heading-has-content,jsx-a11y/alt-text */
import React from "react";

import StaticLayout from "../components/StaticLayout";
import imgSrc1 from "../images/Spork.jpg";
import imgSrc2 from "../images/kaka.jpg";
import imgSrc3 from "../images/coach.jpg";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-37"
        className="post-37 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Lesgevers</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <h3 />
          <p>
            <img
              className="size-medium wp-image-176 aligncenter"
              src={imgSrc1}
              alt=""
              width={200}
              height={300}
            />
          </p>
          <h3>
            <strong>Lesgevers</strong>
          </h3>
          <p>
            Kurt is begonnen met dansen op 11-jarige leeftijd. Hij startte met
            wedstrijden in Latin maar al gauw volgden ook wedstrijden in Mambo,
            Hustle en Showdance. Samen met zijn toenmalige partner Daisy Croes
            (gekend van Sterren op de Dansvloer) danste hij enkele jaren in de
            hoogste klasse (A). Hun samenwerking eindigde toen Daisy besloot om
            professional te worden en Kurt vond een nieuwe danspartner in
            Mareike Enders. Zij werden in 2001 samen Belgisch Kampioen.
          </p>
          <p>
            Kurt was, naast het dansen, ook actief in de zwemwereld. Daar leerde
            hij Vanessa kennen met wie hij al gauw een duo zou vormen (2006).
            Hoewel Vanessa helemaal niets van dansen kende, kon Kurt haar toch
            overtuigen om de cursus ‘Social Dance’ te volgen. Al snel bleek dat
            Vanessa een geboren talent had voor dansen. Vanessa werd op korte
            tijd klaar gestoomd om reeds in november 2006 deel te nemen aan hun
            eerste wedstrijd samen en meteen eentje in de hoogste klasse. Ze
            eindigde knap 5e op het Belgisch Kampioenschap Latin. Ook nadien
            volgden er nog knappe prestaties. Zo werden ze Belgisch Kampioen bij
            de Amateurs en mochten ze ook deelnemen aan verschillende
            internationale wedstrijden en aan Europese- en
            wereldkampioenschappen waar ze de Belgische trots mochten
            verdedigen.
          </p>
          <p>
            <img
              className="size-medium wp-image-187 aligncenter"
              src={imgSrc2}
              alt=""
              width={300}
              height={199}
            />
          </p>
          <p>
            In 2012 namen ze de grote stap om bij de Professionals te gaan
            dansen maar de goden waren hun niet gunstig gezind. Kurt liep een
            ernstige blessure op waardoor ze dat jaar niet op de dansvloer te
            bewonderen waren. Vastberaden om opnieuw te dansen stortte Kurt zich
            op zijn revalidatie. In mei 2013, enkele maanden na zijn
            rugoperatie, leverde deze inspanningen meteen resultaat: Vanessa en
            Kurt werden Belgisch Kampioen bij de Professionals. Ook in 2014
            haalden ze opnieuw de Belgische titel bij de Latin Professionals.
          </p>
          <p>
            Na veel wikken en wegen besloten Vanessa en Kurt in 2015 om hun
            carrière als dansers op non-actief te zetten. Ze gaven toen al
            enkele jaren les aan cursisten en wedstrijdkoppels en besloten om
            hun passie en liefde voor dansen op deze manier door te geven aan
            anderen. In 2017 volgt een nieuwe uitdaging: de&nbsp;oprichting van
            hun eigen dansschool. Als locatie hiervoor kiezen ze voor
            Heusden-Zolder en als gediplomeerde lesgevers bij BULDO zullen ze
            danslessen aanbieden voor jong en oud en van recreatieve danser tot
            wedstrijddanser. Iedereen is welkom! Het volledige aanbod kan u
            bekijken onder ‘dansaanbod’.
          </p>
          <p>
            <img
              className="size-medium wp-image-188 aligncenter"
              src={imgSrc3}
              width={300}
              height={172}
            />
          </p>
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
